import React, { useCallback, useState } from "react";
import { View, Text, ActivityIndicator } from "../";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../Icon";
// STYLES
import Styles from "./Style";
//
import { updateCartItem } from "../../../redux/modules/cart/action";
import { errorMessage, successMessage } from "../../../utils/common";
import { PRIMARY, SECONDARY, THIRD } from "../../../styles/colors";
import { TouchableOpacity } from "../TouchableOpacity";
import { openModal } from "../../../redux/modules/modal/actions";

export default function UpdateCartButton({
  cartItem,
  product = {},
  measurement,
  forCart,
}) {
  const dispatch = useDispatch(),
    [loader, setLoader] = useState(false);
  const open = useSelector((state) => state.modal.openModal);
  const update = useCallback(
    (quantity) => {
      if (product.measurements?.length > 1 && !forCart) {
        dispatch(openModal({ type: "Measurement", data: product }));
        return true;
      }
      setLoader(true);
      const onSuccess = () => setLoader(false);
      const onFail = ({ message }) => {
        errorMessage(message);
        setLoader(false);
      };
      const payload = {
        cartItemId: cartItem.id,
        quantity,
      };
      dispatch(
        updateCartItem({
          data: payload,
          productData: { ...product, measurement },
          onSuccess,
          onFail,
        })
      );
    },
    [cartItem.id]
  );

  return (
    <View style={Styles.container} className="update-cart-button-container">
      <TouchableOpacity
        onPress={loader ? false : () => update(cartItem.quantity - 1)}
        className="imageBox"
      >
        <Icon
          name={"Minus"}
          size={"xs"}
          container={Styles.imageBox}
          imageStyle={Styles.image}
        />
      </TouchableOpacity>
      {!loader ? (
        <Text style={Styles.cartCount}>{cartItem.quantity}</Text>
      ) : (
        <ActivityIndicator color={PRIMARY} style={Styles.loader} />
      )}
      <TouchableOpacity
        onPress={
          loader ||
          cartItem.quantity === 9 ||
          measurement.avilableStockUnit <= cartItem.quantity
            ? null
            : () => update(cartItem.quantity + 1)
        }
        className="imageBox"
      >
        <Icon
          name={"Plus"}
          size={"xs"}
          imageStyle={Styles.image}
          container={{
            ...Styles.imageBox,
            ...{
              opacity:
                cartItem.quantity === 9 ||
                measurement.avilableStockUnit <= cartItem.quantity
                  ? 0.5
                  : 1,
            },
          }}
        />
      </TouchableOpacity>
    </View>
  );
}
