import React from "react";
import { Text, TouchableOpacity, View } from "../";
//
import { Icon } from "../Icon";
import { WHITE } from "../../../styles/colors";

export default function Button({
  label,
  loader = false,
  showIcon = false,
  icon,
  size,
  iconBefore = false,
  className = "",
  iconText = "",
  onPress = () => {},
  disable,
}) {
  return (
    <TouchableOpacity
      onPress={!disable ? onPress : () => {}}
      className={`custom-buttom ${className} ${loader && "loader"} ${
        disable && "disable"
      }`}
    >
      {loader ? (
        <Icon loader={loader} />
      ) : (
        <>
          {iconBefore ? (
            showIcon && (
              <Icon
                loader={loader}
                name={icon}
                size={size}
                imageStyle={{ tintColor: WHITE }}
              />
            )
          ) : (
            <></>
          )}
          <Text className="label">{label}</Text>
          {iconBefore ? (
            <></>
          ) : (
            showIcon && (
              <View className="dFlex alignCenter">
                {iconText}
                <Icon
                  loader={loader}
                  name={icon}
                  size={size}
                  imageStyle={{ tintColor: WHITE }}
                />
              </View>
            )
          )}
        </>
      )}
    </TouchableOpacity>
  );
}
